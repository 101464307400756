import React from "react";
import "./Docs.css";

const TargetIdDocs = () => {
  return (
    <div>
      <header className="api-header">
        <h1>Target Identification API Documentation</h1>
        <p>Detailed API usage and examples for managing training jobs and related resources.</p>
      </header>
      <main>
        <Section title="Create S3 Bucket and Upload Data">
          <p>Before you submit a training job, you need an S3 Bucket to store your data. Here is how you can create a bucket, set a bucket policy, and upload your data.</p>
          <h3>Step 1: Create an S3 Bucket</h3>
          <br></br>
          <pre className="code-block">
            {`aws s3api create-bucket --bucket your-bucket-name --region your-region`}
          </pre>
          <br></br>
          <h3>Step 2: Add a Bucket Policy</h3>
          <p>This policy gives us access to your S3 Bucket</p>
          <pre className="code-block">
            {`{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "AllowAccountAccess",
            "Effect": "Allow",
            "Principal": {
                "AWS": "arn:aws:iam::619071338381:root"
            },
            "Action": [
                "s3:GetObject",
                "s3:PutObject"
            ],
            "Resource": "arn:aws:s3:::{bucket_name}/*"
        }
    ]
}`}
          </pre>
          <br></br>
          <h3>Step 3: Upload Data to the S3 Bucket</h3>
          <pre className="code-block">
            {`aws s3 cp your-file-path s3://your-bucket-name/`}
          </pre>
        </Section>

        <Section title="Submit">
          <p>Submits a training job for processing.</p>
          <Section title="Request Syntax">
            <pre className="code-block">
              {`POST /targetid/submit`}
            </pre>
          </Section>
          <Section title="Request Body">
            <table className="api-table">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><code>Bucket</code></td>
                  <td>String</td>
                  <td>Yes</td>
                  <td>Name of the S3 Bucket your data is stored in.</td>
                </tr>
                <tr>
                  <td><code>Key</code></td>
                  <td>String</td>
                  <td>Yes</td>
                  <td>Name of the file in your S3 Bucket.</td>
                </tr>
                <tr>
                  <td><code>Disease Area</code></td>
                  <td>String</td>
                  <td>Yes</td>
                  <td>Disease area your data pertains to. Can be one of brain, heart, immune, cancer, or metabolic.</td>
                </tr>
                <tr>
                  <td><code>Compute Tier</code></td>
                  <td>Integer</td>
                  <td>Yes</td>
                  <td>GPU power level to use for training. Can be between 1 - 3 inclusive, with 1 being the most powerful and 3 being the least.</td>
                </tr>
              </tbody>
            </table>
          </Section>
          <Section title="Request Syntax">
            <pre className="code-block">
              {`{
    "bucket": "string",
    "key": "string",
    "disease_area": "string",
    "compute_tier": integer
}`}
            </pre>
          </Section>
          <Section title="Response Syntax">
            <pre className="code-block">
              {`{
    "job_id": "uuid",
    "status": "string",
    "error":  "string"
}`}
            </pre>
          </Section>
          <Section title="Response Elements">
            <table className="api-table">
              <thead>
                <tr>
                  <th>Element</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><code>Job Id</code></td>
                  <td>UUID</td>
                  <td>Unique identifier for the training job.</td>
                </tr>
                <tr>
                  <td><code>Status</code></td>
                  <td>string</td>
                  <td>The status of the training job.</td>
                </tr>
                <tr>
                  <td><code>Error</code></td>
                  <td>string</td>
                  <td>Any errors associated with the training job.</td>
                </tr>
              </tbody>
            </table>
          </Section>
        </Section>

        <Section title="Poll">
          <p>Polls the status of a submitted training job.</p>
          <Section title="Request Syntax">
            <pre className="code-block">
              {`GET /targetid/poll?jobId=uuid`}
            </pre>
          </Section>
          <Section title="Request Parameters">
            <table className="api-table">
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><code>Job Id</code></td>
                  <td>UUID</td>
                  <td>Yes</td>
                  <td>Unique identifier for the training job to poll status.</td>
                </tr>
              </tbody>
            </table>
          </Section>
          <Section title="Response Syntax">
            <pre className="code-block">
              {`{
    "job_id": "uuid",
    "status": "string",
    "error": "string"
}`}
            </pre>
          </Section>
          <Section title="Response Elements">
            <table className="api-table">
              <thead>
                <tr>
                  <th>Element</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><code>Job Id</code></td>
                  <td>UUID</td>
                  <td>Unique identifier for the training job.</td>
                </tr>
                <tr>
                  <td><code>Status</code></td>
                  <td>string</td>
                  <td>The current status of your training job.</td>
                </tr>
                <tr>
                  <td><code>Error</code></td>
                  <td>string</td>
                  <td>Any errors associated with the training job.</td>
                </tr>
              </tbody>
            </table>
          </Section>
        </Section>
      </main>
    </div>
  );
};

const Section = ({ title, children }) => (
  <section className="api-section">
    <h2>{title}</h2>
    {children}
  </section>
);

export default TargetIdDocs;
