import React from "react";

const TargetIdGettingStarted = ({ setActiveSection }) => {
  return (
    <section className="api-section">
    <header className="api-header">
        <h1>Target Identification - Getting Started</h1>
        <p>Initial setup for the Target Identification API</p>
      </header>
      <p>
        Before getting started with the Target Identification API, make sure you have completed the steps outlined in the general {" "}
        <button
          className="link-button"
          onClick={() => setActiveSection("gettingStarted")}
        >
          Getting Started 
        </button>
        &nbsp;section. This includes setting up your AWS account and creating an S3 bucket.
      </p>
        <br></br>
      <h3>Step 1: Upload Your `.h5ad` File to S3</h3>
      <p>Once you have set up your S3 bucket, you need to upload your `.h5ad` file to it. Use the following command to upload your file:</p>
      <pre className="code-block">
        {`aws s3 cp your-file-path.h5ad s3://your-bucket-name/`}
      </pre>
        <br></br>
      <h3>Step 2: Continue to the Target Identification API Documentation</h3>
      <p>
        After uploading your `.h5ad` file, you can proceed to the{" "}
        <button
          className="link-button"
          onClick={() => setActiveSection("Target IdentificationApiReference")}
        >
          Target Identification API Documentation
        </button>{" "}
        to learn how to submit a job and interact with the API.
      </p>
    </section>
  );
};

export default TargetIdGettingStarted;
