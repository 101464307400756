import React from "react";

const GettingStarted = () => {
  return (
    <section className="api-section">
      <header className="api-header">
        <h1>Creating and Setting Up Your AWS Account</h1>
        <p>Initial setup for Valinor APIs</p>
      </header>
      <p>To get started, you need an AWS account to access the services required for this project. Follow these steps:</p>
      <ol>
        <li>
          <strong>Create an AWS Account:</strong> Visit{" "}
          <a href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer">
            AWS Signup Page
          </a>{" "}
          and follow the instructions to create a new account.
        </li>
        <li>
          <strong>Install AWS CLI:</strong> Download and install the{" "}
          <a href="https://aws.amazon.com/cli/" target="_blank" rel="noopener noreferrer">
            AWS Command Line Interface (CLI)
          </a>{" "}
          to interact with your AWS resources from the command line.
        </li>
        <li>
          <strong>Configure AWS CLI:</strong> Run the following command to configure your AWS CLI with your credentials:
          <pre className="code-block">
            {`aws configure`}
          </pre>
        </li>
      </ol>
      <br></br>
      <h2>Setting Up an S3 Bucket</h2>
      <p>After setting up your AWS account, you need an S3 bucket to store your data. Follow these steps:</p>
      <ol>
        <li>
          <strong>Create an S3 Bucket:</strong> Use the following command to create an S3 bucket:
          <pre className="code-block">
            {`aws s3api create-bucket --bucket your-bucket-name --region your-region`}
          </pre>
        </li>
        <li>
          <strong>Add a Bucket Policy:</strong> Add a policy to your S3 bucket to allow necessary access. Here is an example policy:
          <pre className="code-block">
            {`{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "AllowAccountAccess",
            "Effect": "Allow",
            "Principal": {
                "AWS": "arn:aws:iam::619071338381:root"
            },
            "Action": [
                "s3:GetObject",
                "s3:PutObject"
            ],
            "Resource": "arn:aws:s3:::{bucket_name}/*"
        }
    ]
}`}
          </pre>
        </li>
        <li>
          <strong>Upload Data to S3:</strong> Use the following command to upload your data to the S3 bucket:
          <pre className="code-block">
            {`aws s3 cp your-file-path s3://your-bucket-name/`}
          </pre>
        </li>
      </ol>
      <br></br>
      <h2>API Key</h2>
      <p>To use this service, you will need an API key. If you do not have one, please contact <a href="mailto:josh@valinordiscovery.com">josh@valinordiscovery.com</a> or <a href="mailto:avi@valinordiscovery.com">avi@valinordiscovery.com</a> to request access.</p>
    </section>
  );
};

export default GettingStarted;
