import React from "react";

const TargetIdTutorial = () => {
  return (
    <section className="api-section">
      <header className="api-header">
        <h1>Target Identification - Tutorial</h1>
        <p>Walkthrough for the Target Identification API</p>
      </header>
      <p>
        Welcome to the Target Identification API tutorial! Follow these steps to get started with uploading your differential expression data and making the necessary API requests.
      </p>
        <br></br>
      <h3>Step 1: Upload Your Differential Expression Data</h3>
      <p>
        Make sure your differential expression data is formatted as an `.h5ad` file. Once formatted, upload it to your S3 bucket using the following command:
      </p>
      <pre className="code-block">
        {`aws s3 cp your-differential-expression-file.h5ad s3://your-bucket-name/`}
      </pre>
      <p>
        Alternatively, you can log into the AWS console on any browser and manually upload your data there.
      </p>
        <br></br>
      <h3>Step 2: Submit the Training Job</h3>
      <p>
        After uploading your data, you need to submit a training job. Use the following API request to submit the job:
      </p>
      <pre className="code-block">
        {`POST https://api.valinordiscovery.com/Target Identification/submit

Headers:
{
    "x-api-key": "{api-key}",
    "Content-Type": "application/json"
}

Request Body:
{
    "bucket": "your-bucket-name",
    "key": "your-differential-expression-file.h5ad",
    "disease_area": "string",  // one of: "brain", "heart", "immune", "cancer", "metabolic"
    "compute_tier": integer    // Choose between 1-3, where 1 is the most powerful
}`}
      </pre>
            <br></br>
      <h3>Step 3: Poll the Status of Your Job</h3>
      <p>
        Once the job is submitted, you can poll the status to check whether the processing is complete. Use the following request to get the job status:
      </p>
      <pre className="code-block">
        {`GET https://api.valinordiscovery.com/Target Identification/poll?jobId=uuid

Headers:
{
    "x-api-key": "{api-key}",
    "Content-Type": "application/json"
}

Response Body:
{
    "job_id": "uuid",
    "status": "string",    // Possible values: "IN_QUEUE", "IN_PROGRESS", "COMPLETED", "FAILED"
    "error": "string"      // Error message if the job failed
}`}
      </pre>
            <br></br>
      <p>
        Once your job status shows as "COMPLETED", you can find your ranked top targets in your S3 bucket.
      </p>
    </section>
  );
};

export default TargetIdTutorial;
