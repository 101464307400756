// import React from "react";
// import "./Docs.css";

// const PerturbDocs = () => {
//   return (
//     <div className="api-doc-container">
//       <header className="api-header">
//         <h1>Perturbation API Documentation</h1>
//         <p>
//           Detailed API usage and examples for preprocessing and predicting
//           perturbations.
//         </p>
//       </header>
//       <main>
//         <Section title="Create S3 Bucket and Upload Data">
//           <p>
//             Before submitting a job, you need an S3 bucket to store your data.
//             Here is how you can create a bucket, set a bucket policy, and upload
//             your data.
//           </p>
//           <h3>Step 1: Create an S3 Bucket</h3>
//           <pre className="code-block">
//             {`aws s3api create-bucket --bucket your-bucket-name --region your-region`}
//           </pre>
//           <h3>Step 2: Add a Bucket Policy</h3>
//           <p>This policy gives the service access to your S3 bucket.</p>
//           <pre className="code-block">
//             {`{
//     "Version": "2012-10-17",
//     "Statement": [
//         {
//             "Sid": "AllowAccountAccess",
//             "Effect": "Allow",
//             "Principal": {
//                 "AWS": "arn:aws:iam::619071338381:root"
//             },
//             "Action": [
//                 "s3:GetObject",
//                 "s3:PutObject"
//             ],
//             "Resource": "arn:aws:s3:::{bucket_name}/*"
//         }
//     ]
// }`}
//           </pre>
//           <h3>Step 3: Upload Data to the S3 Bucket</h3>
//           <pre className="code-block">
//             {`aws s3 cp your-file-path s3://your-bucket-name/`}
//           </pre>
//         </Section>

//         <Section title="Formatting Input Data">
//           <p>
//             Your input data must be in the <code>.h5ad</code> file format, which
//             is commonly used for storing single-cell data. The data should
//             contain specific columns in the observation (<code>obs</code>) and
//             variable (<code>var</code>) annotations to ensure successful
//             preprocessing and prediction.
//           </p>
//           <Section title="Required Columns in adata.obs">
//             <table className="api-table">
//             <thead>
//                 <tr>
//                   <th>Column</th>
//                   <th>Type</th>
//                   <th>Description</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td><code>perturbation</code></td>
//                   <td>String</td>
//                   <td>
//                     Indicates the perturbation applied to each cell. For control cells, use
//                     <code>"control"</code>.
//                   </td>
//                 </tr>
//                 {/* <tr>
//                   <td><code>cell_type</code></td>
//                   <td>String</td>
//                   <td>Specifies the cell type of each cell.</td>
//                 </tr> */}
//                 <tr>
//                   <td><code>cell_line</code></td>
//                   <td>String</td>
//                   <td>
//                     Specifies the cell line of each cell. We recommend appending the naming to indicate
//                     healthy or disease states, for example: <code>"astrocytes_healthy"</code>,{" "}
//                     <code>"astrocytes_AD"</code>.
//                   </td>
//                 </tr>
//                 <tr>
//                   <td><code>dose_value</code></td>
//                   <td>Numeric</td>
//                   <td>
//                     (For drug perturbations) The dosage value applied to the cells. Ensure unit
//                     consistency.
//                   </td>
//                 </tr>
//                 <tr>
//                   <td><code>dose_unit</code></td>
//                   <td>String</td>
//                   <td>
//                     (For drug perturbations) The unit of the dosage, e.g., <code>"uM"</code>.
//                   </td>
//                 </tr>
//               </tbody>
//             </table>
//             <p>
//               Ensure that your <code>cell_line</code> metadata reflects the health status of the cells by
//               naming them appropriately.
//             </p>
//           </Section>
//           <Section title="Required Columns in adata.var">
//             <table className="api-table">
//               <thead>
//                 <tr>
//                   <th>Column</th>
//                   <th>Type</th>
//                   <th>Description</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td>
//                     <code>gene_symbol</code>
//                   </td>
//                   <td>String</td>
//                   <td>Gene symbol for each gene feature.</td>
//                 </tr>
//                 <tr>
//                   <td>
//                     <code>ensembl_id</code>
//                   </td>
//                   <td>String</td>
//                   <td>Ensembl identifier for each gene feature.</td>
//                 </tr>
//               </tbody>
//             </table>
//           </Section>
//           <p>
//             Ensure that your data includes these columns with accurate
//             information to allow for proper preprocessing and prediction.
//           </p>
//         </Section>

//         <Section title="Preprocessing Endpoint">
//           <p>
//             The preprocessing endpoint allows you to submit and monitor
//             preprocessing jobs.
//           </p>

//           <Section title="Submit">
//             <p>Submits a preprocessing job for processing.</p>
//             <Section title="Request Syntax">
//               <pre className="code-block">{`POST /perturbation/preprocess/submit`}</pre>
//             </Section>
//             <Section title="Request Headers">
//               <table className="api-table">
//                 <thead>
//                   <tr>
//                     <th>Header</th>
//                     <th>Value</th>
//                     <th>Required</th>
//                     <th>Description</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       <code>x-api-key</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>Your API key for authentication.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>Content-Type</code>
//                     </td>
//                     <td>application/json</td>
//                     <td>Yes</td>
//                     <td>Indicates the media type is JSON.</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Section>
//             <Section title="Request Body">
//               <table className="api-table">
//                 <thead>
//                   <tr>
//                     <th>Field</th>
//                     <th>Type</th>
//                     <th>Required</th>
//                     <th>Description</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       <code>input_bucket_name</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>
//                       Name of the S3 bucket where your input data is stored.
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>input_file_name</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>File name of your input data in the S3 bucket.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>output_bucket_name</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>
//                       Name of the S3 bucket where the output will be stored.
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>output_file_name</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>Desired file name for the processed output data.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>perturbation_type</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>
//                       Type of perturbation: <code>"gene"</code> or{" "}
//                       <code>"drug"</code>.
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Section>
//             <Section title="Request Example">
//               <pre className="code-block">
//                 {`{
//   "input_bucket_name": "your-input-bucket",
//   "input_file_name": "your-input-file.h5ad",
//   "output_bucket_name": "your-output-bucket",
//   "output_file_name": "processed-output-file.h5ad",
//   "perturbation_type": "gene"
// }`}
//               </pre>
//             </Section>
//             <Section title="Response Syntax">
//               <pre className="code-block">
//                 {`{
//   "id": "job-id",
//   "status": "string",
//   "error": "string (if any)"
// }`}
//               </pre>
//             </Section>
//             <Section title="Response Elements">
//               <table className="api-table">
//                 <thead>
//                   <tr>
//                     <th>Element</th>
//                     <th>Type</th>
//                     <th>Description</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       <code>id</code>
//                     </td>
//                     <td>String</td>
//                     <td>Unique identifier for the preprocessing job.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>status</code>
//                     </td>
//                     <td>String</td>
//                     <td>Status of the job submission.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>error</code>
//                     </td>
//                     <td>String</td>
//                     <td>Error message if the job failed to submit.</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Section>
//           </Section>

//           <Section title="Poll">
//             <p>Checks the status of a submitted preprocessing job.</p>
//             <Section title="Request Syntax">
//               <pre className="code-block">{`GET /perturbation/preprocess/poll?job_id=your-job-id`}</pre>
//             </Section>
//             <Section title="Request Headers">
//               <table className="api-table">
//                 <thead>
//                   <tr>
//                     <th>Header</th>
//                     <th>Value</th>
//                     <th>Required</th>
//                     <th>Description</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       <code>x-api-key</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>Your API key for authentication.</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Section>
//             <Section title="Request Parameters">
//               <table className="api-table">
//                 <thead>
//                   <tr>
//                     <th>Parameter</th>
//                     <th>Type</th>
//                     <th>Required</th>
//                     <th>Description</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       <code>job_id</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>Unique identifier of the preprocessing job to poll.</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Section>
//             <Section title="Response Syntax">
//               <pre className="code-block">
//                 {`{
//   "status": "string",
//   "output_file": "s3://your-output-bucket/processed-output-file.h5ad",
//   "error": "string (if any)"
// }`}
//               </pre>
//             </Section>
//             <Section title="Response Elements">
//               <table className="api-table">
//                 <thead>
//                   <tr>
//                     <th>Element</th>
//                     <th>Type</th>
//                     <th>Description</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       <code>status</code>
//                     </td>
//                     <td>String</td>
//                     <td>Current status of the preprocessing job.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>output_file</code>
//                     </td>
//                     <td>String</td>
//                     <td>Location of the processed output file in S3.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>error</code>
//                     </td>
//                     <td>String</td>
//                     <td>Error message if the job failed.</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Section>
//           </Section>
//         </Section>

//         <Section title="Prediction Endpoint">
//           <p>
//             The prediction endpoint allows you to submit and monitor prediction
//             jobs.
//           </p>

//           <Section title="Submit">
//             <p>Submits a prediction job for processing.</p>
//             <Section title="Request Syntax">
//               <pre className="code-block">{`POST /perturbation/predict/submit`}</pre>
//             </Section>
//             <Section title="Request Headers">
//               <table className="api-table">
//                 <thead>
//                   <tr>
//                     <th>Header</th>
//                     <th>Value</th>
//                     <th>Required</th>
//                     <th>Description</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       <code>x-api-key</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>Your API key for authentication.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>Content-Type</code>
//                     </td>
//                     <td>application/json</td>
//                     <td>Yes</td>
//                     <td>Indicates the media type is JSON.</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Section>
//             <Section title="Request Body">
//               <table className="api-table">
//                 <thead>
//                   <tr>
//                     <th>Field</th>
//                     <th>Type</th>
//                     <th>Required</th>
//                     <th>Description</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       <code>input_bucket_name</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>
//                       Name of the S3 bucket where your input data is stored.
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>input_file_name</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>File name of your input data in the S3 bucket.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>output_bucket_name</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>
//                       Name of the S3 bucket where the prediction outputs will be
//                       stored.
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>output_raw_file_name</code>
//                     </td>
//                     <td>String</td>
//                     <td>No</td>
//                     <td>Desired file name for the raw prediction output.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>output_agg_file_name</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>
//                       Desired file name for the aggregated prediction output.
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>perturb_type</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>
//                       Type of perturbation: <code>"gene"</code> or{" "}
//                       <code>"chemical"</code>.
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>drug</code>
//                     </td>
//                     <td>String</td>
//                     <td>Conditional</td>
//                     <td>
//                       Name of the drug for chemical perturbation. Required if{" "}
//                       <code>perturb_type</code> is <code>"chemical"</code> and
//                       using drug name. Do not include this field for gene perturbations as it will throw an error.
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>smiles</code>
//                     </td>
//                     <td>String</td>
//                     <td>Conditional</td>
//                     <td>
//                       SMILES string of the compound for chemical perturbation.
//                       Required if using SMILES encoding. Do not include this field for gene perturbations as it will throw an error.
//                     </td>
//                   </tr>
//                   <tr>
//                   <td><code>chemfeat</code></td>
//                     <td>String</td>
//                     <td>No</td>
//                     <td>Chemical feature representation: <code>"maccs"</code> or <code>"embedding"</code>. Default is <code>"onehot"</code>. Do not include this field for gene perturbations as it will throw an error.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>gene</code>
//                     </td>
//                     <td>String</td>
//                     <td>Conditional</td>
//                     <td>
//                       Gene symbol(s) for genetic perturbation, separated by{" "}
//                       <code>"+"</code>. Required if <code>perturb_type</code> is{" "}
//                       <code>"gene"</code>. Do not include this field for chemical perturbations as it will throw an error.
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Section>
//             <Section title="Request Example for Genetic Perturbation">
//               <pre className="code-block">
//                 {`{
//   "input_bucket_name": "your-input-bucket",
//   "input_file_name": "your-input-file.h5ad",
//   "output_bucket_name": "your-output-bucket",
//   "output_raw_file_name": "raw-predictions.csv",
//   "output_agg_file_name": "aggregated-predictions.csv",
//   "gene": "AHR+ATL1",
//   "perturb_type": "gene"
// }`}
//               </pre>
//             </Section>
//             <Section title="Request Example for Chemical Perturbation with Drug Name">
//               <pre className="code-block">
//                 {`{
//   "input_bucket_name": "your-input-bucket",
//   "input_file_name": "your-input-file.h5ad",
//   "output_bucket_name": "your-output-bucket",
//   "output_raw_file_name": "raw-predictions.csv",
//   "output_agg_file_name": "aggregated-predictions.csv",
//   "drug": "Droxinostat",
//   "perturb_type": "chemical"
// }`}
//               </pre>
//             </Section>
//             <Section title="Response Syntax">
//               <pre className="code-block">
//                 {`{
//   "id": "job-id",
//   "status": "string",
//   "error": "string (if any)"
// }`}
//               </pre>
//             </Section>
//             <Section title="Response Elements">
//               <table className="api-table">
//                 <thead>
//                   <tr>
//                     <th>Element</th>
//                     <th>Type</th>
//                     <th>Description</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       <code>id</code>
//                     </td>
//                     <td>String</td>
//                     <td>Unique identifier for the prediction job.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>status</code>
//                     </td>
//                     <td>String</td>
//                     <td>Status of the job submission.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>error</code>
//                     </td>
//                     <td>String</td>
//                     <td>Error message if the job failed to submit.</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Section>
//           </Section>

//           <Section title="Poll">
//             <p>Checks the status of a submitted prediction job.</p>
//             <Section title="Request Syntax">
//               <pre className="code-block">{`GET /perturbation/predict/poll?job_id=your-job-id`}</pre>
//             </Section>
//             <Section title="Request Headers">
//               <table className="api-table">
//                 <thead>
//                   <tr>
//                     <th>Header</th>
//                     <th>Value</th>
//                     <th>Required</th>
//                     <th>Description</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       <code>x-api-key</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>Your API key for authentication.</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Section>
//             <Section title="Request Parameters">
//               <table className="api-table">
//                 <thead>
//                   <tr>
//                     <th>Parameter</th>
//                     <th>Type</th>
//                     <th>Required</th>
//                     <th>Description</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       <code>job_id</code>
//                     </td>
//                     <td>String</td>
//                     <td>Yes</td>
//                     <td>Unique identifier of the prediction job to poll.</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Section>
//             <Section title="Response Syntax">
//               <pre className="code-block">
//                 {`{
//   "status": "string",
//   "output_files": ["aggregated-predictions.csv", "raw-predictions.csv"],
//   "error": "string (if any)"
// }`}
//               </pre>
//             </Section>
//             <Section title="Response Elements">
//               <table className="api-table">
//                 <thead>
//                   <tr>
//                     <th>Element</th>
//                     <th>Type</th>
//                     <th>Description</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       <code>status</code>
//                     </td>
//                     <td>String</td>
//                     <td>Current status of the prediction job.</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>output_files</code>
//                     </td>
//                     <td>Array of Strings</td>
//                     <td>
//                       List of output files generated, located in your S3 bucket.
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <code>error</code>
//                     </td>
//                     <td>String</td>
//                     <td>Error message if the job failed.</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </Section>
//           </Section>
//         </Section>
//       </main>
//     </div>
//   );
// };

// const Section = ({ title, children }) => (
//   <section className="api-section">
//     <h2>{title}</h2>
//     {children}
//   </section>
// );

// export default PerturbDocs;

import React from "react";
import "./Docs.css";

const PerturbDocs = () => {
  return (
    <div className="api-doc-container">
      <header className="api-header">
        <h1>Perturbation API Documentation</h1>
        <p>
          Detailed API usage and examples for preprocessing and predicting
          perturbations.
        </p>
      </header>
      <main>
        <Section title="Overview">
          <p>
            The Perturbation API allows you to preprocess single-cell data and
            predict the effects of genetic or chemical perturbations. This
            documentation provides all the necessary information to use the API
            effectively.
          </p>
        </Section>

        <Section title="Create S3 Bucket and Upload Data">
          <p>
            Before submitting a job, you need an S3 bucket to store your data.
            Here is how you can create a bucket, set a bucket policy, and upload
            your data.
          </p>
          <h3>Step 1: Create an S3 Bucket</h3>
          <pre className="code-block">
            {`aws s3api create-bucket --bucket your-bucket-name --region your-region`}
          </pre>
          <h3>Step 2: Add a Bucket Policy</h3>
          <p>This policy gives the service access to your S3 bucket.</p>
          <pre className="code-block">
            {`{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Sid": "AllowAccountAccess",
      "Effect": "Allow",
      "Principal": {
        "AWS": "arn:aws:iam::your-account-id:root"
      },
      "Action": "s3:*",
      "Resource": "arn:aws:s3:::your-bucket-name/*"
    }
  ]
}`}
          </pre>
          <h3>Step 3: Upload Your Data</h3>
          <pre className="code-block">
            {`aws s3 cp your-data-file.h5ad s3://your-bucket-name/`}
          </pre>
        </Section>

        <Section title="Formatting Input Data">
          <p>
            Your input data must be in the <code>.h5ad</code> file format,
            commonly used for storing single-cell data. The data should contain
            specific columns in the observation (<code>obs</code>) and variable
            (<code>var</code>) annotations to ensure successful preprocessing
            and prediction.
          </p>

          <Section title="Required Columns in adata.obs">
            <table className="api-table">
              <thead>
                <tr>
                  <th>Column</th>
                  <th>Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <code>cell_type</code>
                  </td>
                  <td>String</td>
                  <td>Specifies the cell type of each cell.</td>
                </tr>
                <tr>
                  <td>
                    <code>cell_line</code>
                  </td>
                  <td>String</td>
                  <td>
                    Specifies the cell line of each cell. Used if{' '}
                    <code>cell_type</code> is not provided.
                  </td>
                </tr>
                <tr>
                  <td>
                    <code>condition</code>
                  </td>
                  <td>String</td>
                </tr>
              </tbody>
            </table>
            <p>
              Ensure that your <code>cell_type</code> or <code>cell_line</code>{' '}
              metadata is accurate. The preprocessing will standardize cell type
              names by converting them to lowercase.
            </p>
          </Section>

          <Section title="Required Columns in adata.var">
            <p>
              Your gene features should have unique names. The preprocessing
              will ensure that gene names are unique.
            </p>
          </Section>
          <p>
            Ensure that your data includes these columns with accurate
            information to allow for proper preprocessing and prediction.
          </p>
          <p>
            <strong>Note:</strong> Do not include <code>chemfeat</code> for gene
            perturbations, as it will throw an error.
          </p>
        </Section>

        <Section title="Preprocessing Handler">
          <p>
            The preprocessing handler loads your input data from S3, preprocesses
            it, and uploads the processed data back to S3.
          </p>
          <ol>
            <li>
              <strong>Data Preprocessing:</strong> Gene names are made unique,
              cell types are standardized to lowercase, basic quality control is
              performed, data is normalized and log-transformed, highly variable
              genes are identified, and data is subset to these genes.
            </li>
            <li>
              <strong>Data Upload:</strong> The processed data is uploaded back
              to your specified S3 bucket.
            </li>
          </ol>
        </Section>

        <Section title="Prediction Handler">
          <p>
            The prediction handler loads your preprocessed data and performs
            predictions based on the specified perturbation type. It supports
            both gene and chemical perturbations.
          </p>
          <ol>
            <li>
              <strong>Prediction:</strong> Performs predictions using the loaded
              model and processes the output into both raw and aggregated
              predictions.
            </li>
            <li>
              <strong>Data Upload:</strong> Uploads the prediction results back
              to your specified S3 bucket.
            </li>
          <li>
            <strong>Important:</strong> Do not include <code>chemfeat</code> or{' '}
            <code>smiles</code> for gene perturbations or <code>gene</code> for chemical perturbations; it will throw an error.
          </li>
          </ol>

        </Section>

        <Section title="API Endpoints">
          <Section title="Preprocessing Endpoint">
            <p>
              Submit and monitor preprocessing jobs for your input data. Ensure
              that the perturbation type is correctly specified.
            </p>
            <Section title="Request Syntax">
              <pre className="code-block">
                {`POST /perturbation/preprocess/submit`}
              </pre>
            </Section>
            <Section title="Request Body">
              <table className="api-table">
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Type</th>
                    <th>Required</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <code>input_bucket_name</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>
                      Name of the S3 bucket where your input data is stored.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>input_file_name</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>File name of your input data in the S3 bucket.</td>
                  </tr>
                  <tr>
                    <td>
                      <code>output_bucket_name</code>
                    </td>
                    <td>String</td>
                    <td>No (defaults to input bucket)</td>
                    <td>
                      Name of the S3 bucket where the output will be stored.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>output_file_name</code>
                    </td>
                    <td>String</td>
                    <td>No</td>
                    <td>
                      Desired file name for the processed output data. Defaults
                      to:
                    <pre className="code-block">
                            {`"{input_file_name}_processed.h5ad"`}
                          </pre>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <code>perturbation_type</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>
                      Type of perturbation: <code>"gene"</code> or{' '}
                      <code>"chemical"</code>.
                    </td>
                  </tr>
                </tbody>
              </table>
            </Section>
            <Section title="Response Syntax">
              <pre className="code-block">
                {`{
  "id": "job-id",
  "status": "string",
  "error": "string (if any)"
}`}
              </pre>
            </Section>
            <Section title="Polling Endpoint">
              <pre className="code-block">
                {`GET /perturbation/preprocess/poll?jobId=your-job-id`}
              </pre>
            </Section>
          </Section>

          <Section title="Prediction Endpoint">
            <p>
              Submit and monitor prediction jobs based on your preprocessed data.
              You can specify the perturbation type and related parameters.
            </p>
            <Section title="Request Syntax">
              <pre className="code-block">
                {`POST /perturbation/predict/submit`}
              </pre>
            </Section>
            <Section title="Request Body">
              <table className="api-table">
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Type</th>
                    <th>Required</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <code>input_bucket_name</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>
                      Name of the S3 bucket where your input data is stored.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>input_file_name</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>File name of your input data in the S3 bucket.</td>
                  </tr>
                  <tr>
                    <td>
                      <code>output_bucket_name</code>
                    </td>
                    <td>String</td>
                    <td>No (defaults to input bucket)</td>
                    <td>
                      Name of the S3 bucket where the output will be stored.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>output_file_name</code>
                    </td>
                    <td>String</td>
                    <td>No</td>
                    <td>
                      Desired file name for the prediction output data.
                      Automatically generated if not provided as follows:
                      <ul>
                        <li>
                        For <code>gene</code> perturbations:
                          <pre className="code-block">
                            {`"{input_file_name}_perturbation_{identifier}.csv"`}
                          </pre>
                          For <code>chemical</code> perturbations:
                          <pre className="code-block">
                            {`"{input_file_name}_perturbation_{identifier}_{chemfeat}.csv"`}
                          </pre>
                          where:
                          <ul>
                            <li>
                              <code>{`"{input_file_name}"`}</code> is your input file name
                              without the extension.
                            </li>
                            <li>
                              <code>identifier</code> is the provided SMILES
                              string for chemical perturbations or gene symbol(s) for gene perturbations.
                            </li>
                            <li>
                              <code>chemfeat</code> is the chemical feature
                              representation.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>output_raw_file</code>
                    </td>
                    <td>Boolean</td>
                    <td>No (defaults to <code>false</code>)</td>
                    <td>
                      Whether to output raw prediction results. Set to{' '}
                      <code>"true"</code> to enable.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>perturbation_type</code>
                    </td>
                    <td>String</td>
                    <td>Yes</td>
                    <td>
                      Type of perturbation: <code>"gene"</code> or{' '}
                      <code>"chemical"</code>.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>gene</code>
                    </td>
                    <td>String</td>
                    <td>Conditional</td>
                    <td>
                      Gene symbol(s) for genetic perturbation, separated by{' '}
                      <code>"+"</code>. Required if{' '}
                      <code>perturbation_type</code> is <code>"gene"</code>. Do
                      not include <code>chemfeat</code> or <code>smiles</code>{' '}
                      for gene perturbations.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>smiles</code>
                    </td>
                    <td>String</td>
                    <td>Conditional</td>
                    <td>
                      SMILES string of the compound for chemical perturbation.
                      Required if <code>perturbation_type</code> is{' '}
                      <code>"chemical"</code>.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <code>chemfeat</code>
                    </td>
                    <td>String</td>
                    <td>No (defaults to <code>"embedding"</code>)</td>
                    <td>
                      Chemical feature representation: <code>"maccs"</code> (Molecular ACCess System), or 
                      <code>"embedding"</code> (AutoTokenizing process). Do not
                      include for gene perturbations.
                    </td>
                  </tr>
                </tbody>
              </table>
            </Section>
            <Section title="Response Syntax">
              <pre className="code-block">
                {`{
  "id": "job-id",
  "status": "string",
  "output_files": ["file1.csv", "file2.csv"],
  "error": "string (if any)"
}`}
              </pre>
            </Section>
            <Section title="Polling Endpoint">
              <pre className="code-block">
                {`GET /perturbation/predict/poll?jobId=your-job-id`}
              </pre>
            </Section>
            <p>
              Replace <code>your-job-id</code> with the ID returned when you
              submitted the job.
            </p>
          </Section>

          <Section title="Example Requests">
            <Section title="Gene Perturbation">
              <pre className="code-block">
                {`{
  "input_bucket_name": "your-input-bucket",
  "input_file_name": "your-processed-input-file.h5ad",
  "output_bucket_name": "your-output-bucket",
  "output_file_name": "gene-perturbation-results.csv",
  "perturbation_type": "gene",
  "gene": "AHR+ATL1"
}`}
              </pre>
            </Section>
            <Section title="Chemical Perturbation">
              <pre className="code-block">
                {`{
  "input_bucket_name": "your-input-bucket",
  "input_file_name": "your-processed-input-file.h5ad",
  "output_bucket_name": "your-output-bucket",
  "output_file_name": "chemical-perturbation-results.csv",
  "perturbation_type": "chemical",
  "smiles": "CC(=O)Oc1ccccc1C(=O)O",
  "chemfeat": "maccs"
}`}
              </pre>
            </Section>
          </Section>
        </Section>

        <Section title="Response Elements">
          <table className="api-table">
            <thead>
              <tr>
                <th>Element</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <code>id</code>
                </td>
                <td>String</td>
                <td>Unique identifier for the job.</td>
              </tr>
              <tr>
                <td>
                  <code>status</code>
                </td>
                <td>String</td>
                <td>
                  Current status of the job: <code>"pending"</code>,{' '}
                  <code>"running"</code>, <code>"completed"</code>, or{' '}
                  <code>"error"</code>.
                </td>
              </tr>
              <tr>
                <td>
                  <code>output_files</code>
                </td>
                <td>Array of Strings</td>
                <td>
                  List of output files generated. Available when{' '}
                  <code>status</code> is <code>"completed"</code>.
                </td>
              </tr>
              <tr>
                <td>
                  <code>error</code>
                </td>
                <td>String</td>
                <td>Error message if the job failed.</td>
              </tr>
            </tbody>
          </table>
        </Section>

        <Section title="Error Handling">
          <p>
            If there are any errors during preprocessing or prediction, the
            response will include an <code>error</code> field with a descriptive
            message. Common errors include:
          </p>
          <ul>
            <li>
              <strong>Invalid Input:</strong> Missing required fields or invalid
              parameter values. Check that all required fields are provided and
              correctly formatted.
            </li>
            <li>
              <strong>Data Loading Issues:</strong> Problems accessing the S3
              bucket or reading the input file. Ensure that the S3 bucket and
              file names are correct and that appropriate permissions are set.
            </li>
            <li>
              <strong>Model Loading Errors:</strong> The required model could
              not be loaded. This might be due to an incorrect{' '}
              <code>perturbation_type</code> or unavailable model for the
              specified parameters.
            </li>
          </ul>
        </Section>

        <Section title="Additional Notes">
          <ul>
            <li>
              <strong>Data Privacy:</strong> Ensure that you comply with all
              relevant data privacy regulations when using the API.
            </li>
            <li>
              <strong>Resource Limits:</strong> Be aware of any resource limits
              or quotas that may apply when using the API or AWS services.
            </li>
            <li>
              <strong>Support:</strong> If you encounter issues not covered in
              this documentation, please contact support for assistance.
            </li>
          </ul>
        </Section>
      </main>
    </div>
  );
};

const Section = ({ title, children }) => (
  <section className="api-section">
    <h2>{title}</h2>
    {children}
  </section>
);

export default PerturbDocs;