// PerturbationTutorial.js
import React from "react";

const PerturbationTutorial = () => {
  return (
    <section className="api-section">
      <header className="api-header">
        <h1>Perturbation API - Tutorial</h1>
        <p>Walkthrough for the Perturbation API</p>
      </header>
      <p>
        Welcome to the Perturbation API tutorial! Follow these steps to preprocess your data and make predictions.
      </p>
      <h3>Step 1: Upload Your Differential Expression Data</h3>
      <p>
        Make sure your differential expression data is formatted as an `.h5ad` file. Once formatted, upload it to your S3 bucket using the following command:
      </p>
      <pre className="code-block">
        {`aws s3 cp your-differential-expression-file.h5ad s3://your-bucket-name/`}
      </pre>
      <p>
        Alternatively, you can log into the AWS console on any browser and manually upload your data there.
      </p>
        <br></br>
      <h3>Step 2: Preprocess Your Data</h3>
      <p>
        Submit a preprocessing job using the following API request:
      </p>
      <pre className="code-block">
        {`POST https://api.valinordiscovery.com/perturbation/preprocess/submit

Headers:
{
    "x-api-key": "{api-key}",
    "Content-Type": "application/json"
}

Request Body:
{
    "input_bucket_name": "your-input-bucket",
    "input_file_name": "your-input-file.h5ad",
    "output_bucket_name": "your-output-bucket",
    "output_file_name": "processed-output-file.h5ad",
    "perturbation_type": "gene" // or "chemical"
}`}
      </pre>
      <p>
        Replace the placeholders with your actual bucket names and file names.
      </p>
      <h3>Step 3: Poll the Status of Your Preprocessing Job</h3>
      <p>
        To check the status of your preprocessing job, use the following request:
      </p>
      <pre className="code-block">
        {`GET https://api.valinordiscovery.com/perturbation/preprocess/poll?jobId=your-job-id

Headers:
{
    "x-api-key": "{api-key}"
}

Possible Response Bodies:

// If the job is in queue
{
  "id": "your-job-id",
  "status": "IN_QUEUE"
}

// If the job has completed successfully
{
  "delayTime": 13285,
  "executionTime": 263951,
  "id": "your-job-id",
  "output": {
    "output_files": ["processed-output-file.h5ad"],
    "status": "success"
  },
  "status": "COMPLETED",
  "workerId": "worker-id"
}

// If the job has completed with an error
{
  "delayTime": 11221,
  "executionTime": 705428,
  "id": "your-job-id",
  "output": {
    "message": "Error message detailing the issue",
    "status": "error"
  },
  "status": "COMPLETED",
  "workerId": "worker-id"
}`}
      </pre>
      <p>
        Once the job status is <code>"COMPLETED"</code> and the <code>"output"</code> contains the <code>"output_files"</code>, your processed file will be available in your output S3 bucket.
      </p>
      <h3>Step 4: Make Predictions</h3>
      <p>
        After preprocessing, you can submit a prediction job:
      </p>
      <pre className="code-block">
        {`POST https://api.valinordiscovery.com/perturbation/predict/submit

Headers:
{
    "x-api-key": "{api-key}",
    "Content-Type": "application/json"
}

Request Body (for gene perturbation):
{
    "input_bucket_name": "your-input-bucket",
    "input_file_name": "processed-output-file.h5ad",
    "output_bucket_name": "your-output-bucket",
    "output_file_name": "gene-perturbation-results.csv",
    "perturbation_type": "gene",
    "gene": "gene(s)" // e.g "AHR+ATL1"
}

Request Body (for chemical perturbation):
{
    "input_bucket_name": "your-input-bucket",
    "input_file_name": "processed-output-file.h5ad",
    "output_bucket_name": "your-output-bucket",
    "output_file_name": "chemical-perturbation-results.csv",
    "perturbation_type": "chemical",
    "smiles": "smiles string", // e.g. "CC(=O)Oc1ccccc1C(=O)O"
    "chemfeat": "embedding" // "maccs"
}`}
      </pre>
      <h3>Step 5: Poll the Status of Your Prediction Job</h3>
      <p>
        Use the following request to poll the prediction job status:
      </p>
      <pre className="code-block">
        {`GET https://api.valinordiscovery.com/perturbation/predict/poll?jobId=your-job-id

Headers:
{
    "x-api-key": "{api-key}"
}

Possible Response Bodies:

// If the job is in queue
{
  "id": "your-job-id",
  "status": "IN_QUEUE"
}

// If the job has completed successfully
{
  "id": "your-job-id",
  "output": {
    "output_files": ["perturbation-results.csv"],
    "status": "success"
  },
  "status": "COMPLETED"
}

// If the job has completed with an error
{
  "id": "your-job-id",
  "output": {
    "message": "Error message detailing the issue",
    "status": "error"
  },
  "status": "COMPLETED"
}`}
      </pre>
      <p>
        Once the job status is <code>"COMPLETED"</code> and the <code>"output"</code> contains the <code>"output_files"</code>, your results will be available in the specified output bucket.
      </p>
    </section>
  );
};

export default PerturbationTutorial;