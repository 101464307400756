import React, { useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import SearchComponent from './components/Search';
import Graph from './components/GeneNetwork';
import Login from './components/Login'; // Import the login component
import Docs from './components/Docs';
import TargetIdDocs from './components/TargetIdDocs';
import PerturbDocs from './components/PerturbDocs';
import PrivateRoute from './components/PrivateRoute'; // Import the private route component
import './App.css';
import './index.css';

function App() {
  const graphRef = useRef(null);
  const [selectedGene, setSelectedGene] = useState(null);
  const [newNodes, setNewNodes] = useState(null);

  const handleGeneSelect = (gene) => {
    if (graphRef.current) {
      const cy = graphRef.current.getCy();
      const node = cy.getElementById(gene.id);
      if (node) {
        cy.animate({
          fit: {
            eles: node,
            padding: 250
          },
          center: {
            eles: node
          },
          duration: 1000
        });
      }
    }
  };

  const handleFileUpload = (data) => {
    setNewNodes(data);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={
        <div className="homepage">
          <div id="maincontent">
              <h2>Valinor Discovery</h2>
              <p><strong>At Valinor, we are revolutionizing drug development with advanced machine learning models designed to automate the entire process—from identifying drug targets to optimizing clinical trials and enabling personalized medicine.</strong></p>
              <br></br>
              <p>Today, over 90% of therapeutics fail before reaching patients. Our mission is to drastically improve this success rate, saving drug developers billions of dollars while delivering life-changing therapeutics to patients faster than ever before.</p>
              <br></br>
              <p>We’ve assembled a world-class team of machine learning engineers and biologists who are passionate about accelerating therapeutic discovery and development. Our models already outperform industry leaders, setting a new standard for efficiency and innovation in ML for drug development.</p>
              <br></br>
              <p>Our team brings experience from institutions like Harvard Medical School, Dartmouth’s Thayer School of Engineering, and multiple venture-backed ML startups. Our advisors include founders of cutting-edge ML companies, business development leaders from global biotech unicorns, and senior drug discovery scientists from public pharma companies.</p>
              <br></br>
              <p>Our team includes individuals with experience at labs at Harvard Medical School, the Thayer School of Engineering at Dartmouth, as well as multiple venture-backed ML startups. Our advisors include founders of bleeding-edge ML companies, business development leaders from global biotech unicorns, and heads of aging-focused research labs.</p>
              <br></br>
              <p>We’re hiring! If you’re excited to work on revolutionary machine learning models in biology, contact our CEO at <a href="mailto:josh@valinordiscovery.com">josh@valinordiscovery.com</a>.</p>
              <p class="quote">"For in Valinor they have no trial of death...and so great was their bliss that it could not be measured."<br/>- The Silmarillion</p>
          </div>
      </div>
    } />

        <Route
          path="/demo"
          element={
            <PrivateRoute element={() => (
              <div className="App">
                <div name="search-section" className="search-section flex justify-center">
                  <SearchComponent onGeneSelect={handleGeneSelect} onFileUpload={handleFileUpload} newNodes={newNodes} />
                </div>
                <div className="graph-wrapper">
                  <div className="graph-container">
                    <Graph ref={graphRef} selectedGene={selectedGene} newNodes={newNodes} />
                  </div>
                </div>
              </div>
            )} />
          }
        />

        {/* Login page route */}
        <Route path="/login" element={<Login />} />
        <Route path="/docs/targetid" element={<TargetIdDocs />} />
        <Route path="/docs/perturb" element={<PerturbDocs />} />
        <Route path="/docs/new" element={<Docs />} />
      </Routes>
    </Router>
  );
}

export default App;
